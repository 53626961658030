<template>
  <div>
    <p class="msg">
      <van-icon
        name="arrow-left"
        size="0.34rem"
        style="margin-left: 0.2rem"
        @click="$router.push('/resume1')"
      />
      <span class="wan">过往履历 </span>
      <span class="yl" @click="$router.push('/preview')">简历预览</span>
    </p>
    <div class="identity">
      <p class="buddha">
        <span class="sr ss" @click="$refs.addressid.addressmeth('2', '')">求职区域</span>
        <input
          readonly="readonly"
          class="lian"
          type="text"
          v-model="address"
          placeholder="请选择所在区域"
          style="text-align: right; width: 5rem"
          @click="$refs.addressid.addressmeth('2', '')"
        />
      </p>
      <p class="buddha">
        <span class="sr ss" @click="$router.push('/record')">过往履历</span>
        <!-- <input
          type="text"
          class="lian"
          v-model="advantage"
          @click="$router.push('/advantage')"
        /> -->
        <span class="tj" @click="$router.push('/record')">添加过往履历</span>
        <van-icon
          name="add-o"
          size="0.6rem"
          @click="$router.push('/record')"
          style="float: right"
        />
      </p>
      <p class="pp">往届工作履历</p>
      <div
        class="box"
        v-for="(item, index) in list"
        @click.stop="xg(item)"
        :key="index"
      >
        <div class="title">
          <span>{{ item.company_name }}</span>
        </div>
        <span class="position">工作职位：{{ item.position }}</span>
        <span class="item">{{ item.entry_time }}～{{ item.quit_time }}</span>
        <!-- <p class="p">
          {{ item.jobresume }}
        </p> -->
        <textarea rows="3" v-model="item.working_experience" disabled></textarea>
        <div class="btn">
          <button @click.stop="del(item)">删除</button>
          <button @click.stop="xg(item)">编辑</button>
        </div>
      </div>
      <p class="buddha">
        <span class="sr">职业资格证书/荣誉奖项 </span>
        <!-- <input
          class="lian"
          type="text"
          v-model="honor"
          placeholder="请填写荣誉奖项"
          @input="honors"
          style="text-align: right"
        /> -->
      </p>
      <textarea
        class="txt"
        name=""
        id=""
        cols="30"
        placeholder="请填写荣誉奖项(选填)"
        v-model="honor"
        rows="10"
        @input="honors"
      ></textarea>

      <div class="qd" @click="share">确认完成</div>
    </div>
    <addresses
        :msg="2"
        @addressreturn="addressreturn"
        ref="addressid"
      ></addresses>
      <div style="height:.3rem"></div>
  </div>
</template>

<script>
import Addressinfo from "../util/Area";
import addresses from "../components/address.vue"
export default {
  components:{
    addresses
  },
  data() {
    return {
      show: false,
      list: [],
      advantage: "",
      address: "",
      honor: "", //荣誉
      areaList: Addressinfo,
      kk: "",
    };
  },
  created() {
    let allmsg = JSON.parse(localStorage.getItem("allmsg"));
    console.log(allmsg);
    this.address = localStorage.getItem("area");
    this.honor = localStorage.getItem("honor");
    console.error(JSON.parse(localStorage.getItem("list")));
    // let aaa = JSON.parse(localStorage.getItem("wz"));
    if (localStorage.getItem("list") == "") {
      localStorage.removeItem("list");
      localStorage.getItem("list");
      this.list = arr;
    } else {
      let a = JSON.parse(localStorage.getItem("list"))
      
      this.list = a;
      console.error("-====",this.list);
    }

    if(!this.list&&allmsg.work_resume){
      let a = JSON.parse(allmsg.work_resume);
      localStorage.setItem("list",allmsg.work_resume);
    
      this.list = a;
    }

    // console.log(aaa);
    // console.log(arr);
  },
  mounted() {
    this.kk = this.settime(this);
  },
  methods: {
    //确认求职区域
    addressreturn(msg){
      console.log(msg)
      this.address = msg[0].name+'/'+msg[1].name;
      localStorage.setItem("area", this.address);
    },
    //同步保存职业资格证书/荣誉奖项
    honors() {
      localStorage.setItem("honor", this.honor);
    },
    //延时器
    settime(ss) {
      var aa;
      clearTimeout(aa);
      return function () {
        aa = setTimeout(() => {
          console.log("aaa");
          ss.$router.push({
            name: "ending",
            params: {
              num: 1,
            },
          });
        }, 1000);
      };
    },
    del(data) {
      console.log(data);
      this.$dialog
        .confirm({
          title: "警告",
          message: "是否删除本条履历",
        })
        .then(() => {
          this.list = this.list.filter((item, index) => {
            return item.id != data.id;
          });
          localStorage.setItem("list", JSON.stringify(this.list));
        })
        .catch(() => {
          // on cancel
        });
    },
    //保存内容进入结束页面
    share() {
      let id = localStorage.getItem("id"); //简历id
      let list = JSON.parse(localStorage.getItem("list")); //履历
      this.address = localStorage.getItem("area"); //地区
      let honor = localStorage.getItem("honor"); //荣誉
      let lists = [];
      if (list == null) {
        list = [];
      }
      list.forEach((item) => {
        let arr = {
          company_name: item.company_name,
          entry_time: item.entry_time,
          quit_time: item.quit_time,
          position: item.position,
          working_experience: item.working_experience,
          // my_duty: item.responsibilities,
        };
        lists.push(arr);
      });
      if (this.address == null || this.address == "") {
        this.$toast.fail("请填写地区");
        return;
      }
      if (lists.length == 0) {
        this.$toast.fail("请填写一份履历");
        return;
      }
      console.log(lists);
      this.$http
        .post("/firm/v1/Resume/improveInfo", {
          reqType: "resume",
          id: id,
          honor: this.honor,
          area: this.address.split("/"),
          work_resume: lists,
          pageno: 4,
        })
        .then((res) => {
          res = JSON.parse(res.data);
          console.log(res);
          if (res.code == 0) {
            setTimeout((res) => {}, 2000);
            this.$toast.success("分享成功");
            this.kk();
          } else {
            this.$toast.fail(res.msg);
          }
        })
        .catch((eer) => {
          console.log(eer);
        });
    },
    //跳转页面修改本条履历
    xg(item) {
      this.$router.push({
        name: "record",
        params: {
          item:JSON.stringify(item),
        },
      });
    },

    //打开求职区域插件
    showPopup() {
      this.show = true;
    },
  },
};
</script>

<style scoped lang='less'>
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
}
.tj {
  margin-left: 30%;
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #c4c4c4;
  white-space: nowrap;
}
.pp {
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin: 0.6rem 0;
}
.btn {
  display: flex;
  margin-left: 4rem;
  margin-bottom: 0.41rem;
  button {
    width: 1.33rem;
    height: 0.57rem;
    margin-left: 0.18rem;

    border: 0.02rem solid #f55d10;
    border-radius: 0.1rem;
    color: #f55d10;
    background: #ffe6d9;
    font-size: 0.24rem;
    font-family: PingFangSC-Medium, PingFang SC;
  }
}
.zz {
  border-bottom: 0 none !important;
}
.txt {
  width: 100%;
  height: 2rem;
  font-size: 0.26rem;
  resize: none;
}
.ss::before {
  content: "*";
  color: #ff0000;
  font-size: 0.25rem;
  position: relative;
  margin-right: 0.1rem;
  /* top: 0.1rem; */
}
.item {
  float: right;
  // position: absolute;
  // top: 30px;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  right: 0;
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ccc;
}
.qd {
  width: 6.86rem;
  height: 0.96rem;
  line-height: 0.96rem;
  text-align: center;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
  margin-left: 50%;
  transform: translateX(-50%);
}
.yl {
  margin-left: 1rem;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  white-space: nowrap;
}
.box {
  // position: relative;

  border-bottom: 1px solid #ccc;
  margin-bottom: 0.2rem;
  .p {
    // position: absolute;
    // top: 1rem;
    font-size: 0.3rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    word-break: break-all;
    display: -webkit-box; /**对象作为伸缩盒子模型展示**/
    -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp: 3; /**显示的行数**/
    overflow: hidden; /**隐藏超出的内容**/
  }
  .title {
    display: flex;
    justify-content: space-between;
    font-size: 0.34rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
  }
  textarea {
    resize: none;
    // position: absolute;
    // top: 1rem;

    width: 100%;
    height: 1.6rem;
    font-size: 0.3rem;
    border: 0 none;
  }
  textarea:disabled {
    background-color: #fff;
  }
  .position {
    float: left;
    // position: absolute;
    // top: 30px;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    font-size: 0.2rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
  }
}
.msg .wan {
  margin-left: 35%;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
.identity {
  margin: 0rem 0.32rem;
}

.identity .buddha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 0.01rem #eee;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
  line-height: 1.46rem;
}

.identity .buddha .lian {
  border: none;
}
</style>